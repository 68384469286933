<template>
  <div>
    <!--<sdPageHeader title="Participantes registrados" class="title-bold-extra" style="background: white;">
      <template v-slot:buttons>
        <div class="page-header-actions">
          <router-link to="/my-events/index">
            <Button class="btn-without" style="width: 9rem;">
              <sdFeatherIcons type="arrow-left" size="14" style="color: #e70e4c;" />
              Regresar
            </Button>
          </router-link>
          <Button class="btn-add-event title-normal" style="width: 9rem;" v-on:click="handleGenerateReport">
            Generar reporte
          </Button>
        </div>
      </template>
    </sdPageHeader>-->
    <Main style="margin-top: 2rem;">
      <ParticipantsOverview :generateReport="dataView.generateReport" />
    </Main>
  </div>
</template>

<script>
import { Main } from '../styled';
import { useStore } from 'vuex';
//import { useRouter } from "vue-router";
import { defineAsyncComponent, reactive } from 'vue';
//import { getItem, setItem } from '../../utility/localStorageControl';
//import { authWithSsid } from '../../services/auth';

const ParticipantsOverview = defineAsyncComponent(() => import('./overview/participants/ParticipantsOverview'));

export default {
  name: 'Participants',
  components: {
    Main,
    ParticipantsOverview,
  },
  setup() {
    const { dispatch } = useStore();
    dispatch('changeTitleHeader', "Visualización de Participantes");

    const dataView = reactive({
      generateReport: false,
    });

    const handleGenerateReport = () => {
      dataView.generateReport = !dataView.generateReport;
    }

    return {
      dataView,
      handleGenerateReport
    };
  },
};
</script>
<style scoped>
.box-event {
  border: 1px solid #bcbdbe;
  border-radius: 7px;
  padding: 0 !important;
  margin-left: 0.5rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
}
.btn-add-event {
  background: #e70e4c;
  color: #fff;
  border-radius: 6px;
  border: none;
}
.btn-without {
  background: #fff;
  color: #e70e4c;
  border: none;
}
button:hover {
  cursor: pointer;
}
.title-more-events {
  color: #e70e4c;
  font-size: 14px;
  font-weight: bold;
}
.title-more-events:hover {
  cursor: pointer;
}
</style>
